import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';

import check from '../../../../assets/CreationListingProcess/check.png';
import cross from '../../../../assets/CreationListingProcess/cross.svg';
import desktop from './desktop_mac.svg';
import wifi from './wifi.svg';
import landscape from './landscape.svg';
import coffee from './access_time.svg';

import css from './SectionComparison.module.css';
import { Button, Logo, NamedLink } from '../../../../components';

// Section component that shows features.
// Block content are shown in a row-like way:
// [image] text
// text [image]
// [image] text
const SectionComparison = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = blocks?.length > 0;

  return (
    <SectionContainer
      className={classNames(className, css.root)}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={classNames(defaultClasses.title, css.title)} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}
      <div className={css.comparison}>
        <div className={css.block}>
          <div className={css.blockTitleFiller}>
          </div>
          <div className={css.blockTextHeader}>
            <img src={desktop} alt="desktop" className={css.icon} />
            <span>Workspace set-up</span>
          </div>
          <div className={css.blockTextHeader}>
            <img src={wifi} alt="wifi" className={css.icon} />
            <span>Reliable Wifi</span>
          </div>
          <div className={css.blockTextHeader}>
            <img src={landscape} alt="landscape" className={css.icon} />
            <span>Unique setting</span>
          </div>
          <div className={css.blockTextHeader}>
            <img src={coffee} alt="coffee" className={css.icon} />
            <span>Coffee</span>
          </div>
          <div className={css.blockTitleFiller}>
          </div>
        </div>
        <div className={css.blockHomebase}>
          <div className={css.blockTitle}>
            <Logo className={css.logo} />
          </div>
          <div className={css.blockText}>
            <div className={css.blockMobileTextH}>
              Worksapce set-up
            </div>
            <img src={check} alt="check" className={css.check} />
          </div>
          <div className={css.blockText}>
            <div className={css.blockMobileTextH}>
              Reliable Wifi
            </div>
            <img src={check} alt="check" className={css.check} />
          </div>
          <div className={css.blockText}>
            <div className={css.blockMobileTextH}>
              Unique setting
            </div>
            <img src={check} alt="check" className={css.check} />
          </div>
          <div className={css.blockText}>
            <div className={css.blockMobileTextH}>
              Coffee
            </div>
            <img src={check} alt="check" className={css.check} />
          </div>
          <div className={css.blockTitle}>
            <NamedLink className={css.button} name="SearchPage">
              <span className={css.buttonText}>
                Book now
              </span>
            </NamedLink>
          </div>
        </div>
        <div className={css.block}>
          <div className={css.blockTitle}>
            <div>
              <span>STR Portals</span><br />
              <span className={css.subtitle}>
                (eg: Airbnb)
              </span>
            </div>
          </div>
          <div className={css.blockText}>
            <div className={css.blockMobileText}>
              Worksapce set-up
            </div>
            <img src={cross} alt="cross" className={css.check} />
          </div>
          <div className={css.blockText}>
            <div className={css.blockMobileText}>
              Reliable Wifi
            </div>
            <span>Not guaranteed</span>
          </div>
          <div className={css.blockText}>
            <div className={css.blockMobileText}>
              Unique setting
            </div>
            <span>Not guaranteed</span>
          </div>
          <div className={css.blockText}>
            <div className={css.blockMobileText}>
              Coffee
            </div>
            <span>Not guaranteed</span>
          </div>
          <div className={css.blockTitleFiller}>
          </div>
        </div>
        <div className={css.block}>
          <div className={css.blockTitle}>
            <span>Hotels</span>
          </div>
          <div className={css.blockText}>
            <div className={css.blockMobileText}>
              Worksapce set-up
            </div>
            <img src={cross} alt="cross" className={css.check} />
          </div>
          <div className={css.blockText}>
            <div className={css.blockMobileText}>
              Reliable Wifi
            </div>
            <img src={cross} alt="cross" className={css.check} />
          </div>
          <div className={css.blockText}>
            <div className={css.blockMobileText}>
              Unique setting
            </div>
            <span>Not guaranteed</span>
          </div>
          <div className={css.blockText}>
            <div className={css.blockMobileText}>
              Coffee
            </div>
            <span>Not guaranteed</span>
          </div>
          <div className={css.blockTitleFiller}>
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionComparison.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionComparison.propTypes = {
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionComparison;
