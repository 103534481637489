import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionBlogArticle.module.css';

// Section component that's able to show article content
// The article content is mainly supposed to be inside a block
const SectionBlogArticle = props => {
    const {
        sectionId,
        className,
        rootClassName,
        defaultClasses,
        title,
        description,
        appearance,
        callToAction,
        blocks,
        isInsideContainer,
        options,
    } = props;

    // If external mapping has been included for fields
    // E.g. { h1: { component: MyAwesomeHeader } }
    const fieldComponents = options?.fieldComponents;
    const fieldOptions = { fieldComponents };

    const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
    const hasBlocks = blocks?.length > 0;

    return (
        <SectionContainer
            className={classNames(className, css.hasShort)}
            rootClassName={rootClassName}
            appearance={appearance}
            options={fieldOptions}
        >
            {hasHeaderFields ? (
                <header className={defaultClasses.sectionDetails}>
                    <Field data={description} className={css.description} options={fieldOptions} />
                    <Field data={title} className={css.title} options={fieldOptions} />
                    <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
                </header>
            ) : null}
            {hasBlocks ? (
                <div
                    className={classNames(defaultClasses.blockContainer, css.articleMain, {
                        [css.noSidePaddings]: isInsideContainer,
                    })}
                >
                    <BlockBuilder
                        blocks={blocks}
                        ctaButtonClass={defaultClasses.ctaButton}
                        options={options}
                    />
                </div>
            ) : null}
        </SectionContainer>
    );
};

const propTypeOption = shape({
    fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionBlogArticle.defaultProps = {
    className: null,
    rootClassName: null,
    defaultClasses: null,
    textClassName: null,
    title: null,
    description: null,
    appearance: null,
    callToAction: null,
    blocks: [],
    isInsideContainer: false,
    options: null,
};

SectionBlogArticle.propTypes = {
    className: string,
    rootClassName: string,
    defaultClasses: shape({
        sectionDetails: string,
        title: string,
        description: string,
        ctaButton: string,
    }),
    title: object,
    description: object,
    appearance: object,
    callToAction: object,
    blocks: arrayOf(object),
    isInsideContainer: bool,
    options: propTypeOption,
};

export default SectionBlogArticle;
